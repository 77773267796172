import React, { useState, useEffect } from "react";
import { Button ,Container} from "react-bootstrap";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Col, Row, Divider, DatePicker, Select, Space } from "antd";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function Withdrawhistory() {
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date_time, setDate] = useState("");
    const [loading1, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(undefined);
  const [loading2, setLoading2] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const handleReload = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    loaduser("", dates);
  };
  useEffect(() => {
    loaduser();
  }, []);
  const loaduser = async () => {
    // setActiveButton(dateRange);
    
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    setLoading(true);

    let url = `${process.env.REACT_APP_API_URL}/wallet_history.php`;
    //  console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // alert(user_id)
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    formData.append("type", "2");


    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        //  alert(res);
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <>
    <Container>
      <section className="margin-bottom-88" id="history">
        <div className="container-fluid paddingall-5">
          {/* <div className="historybet marketdetails"> */}
          {/* <Divider orientation="center">History</Divider> */}
          {/* 
            <Row gutter={16}>
              
                            <Col span={4}>
                                <div className="reloadhistory" onClick={handleReload}>
                                    <i class="bi bi-arrow-clockwise"></i>
                                </div>
                            </Col>
            </Row> */}
          {/* </div> */}
          <div className="table-responsive">
            <div className="table table-history">
              <div>
                {/* <th>S.NO</th> */}
                {/* <th>Sr no.</th> */}
                {/* <th>Amount</th>
                    <th>txn iD</th>
                    <th>status </th>
                    <th>Date </th> */}

                {/* <th>Points </th>
                    <th>Resulet</th>
                    <th>Action</th> */}
                {/* {user.is_result_declared} */}
                {/* {users.tr_nature === 'TRWITH003' ? ( */}
                {loading1 ? (
                  <div className="spinner-wrapper">
                    <div className="loadernew2"></div>
                  </div>
                ) : (
                  <div className="bg-transparent">
                    {users && users.length > 0 ? (
                      users.map((user, index) => (
                        <React.Fragment key={index}>
                          {user.tr_nature === "TRWITH003" ? (
                            <div className="mb-2 withbox">
                              {/* <td>{index + 1}</td> */}
                              {/* <td>{user.date_time}</td> */}
                              <div className="bg-dark text-light text-center py-2 rounded">
                                Withdrawal History
                              </div>

                              <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-dark">Transaction Id</p>
                                <span className="text-yellow">{user.transaction_id}</span>
                              </div>
                              <div>
                                <p className="text-dark">Amount</p>
                                <span className="text-yellow"> {user.tr_value}</span>
                              </div>
                              {/* <td>{user.tr_status}</td> */}
                              <div className="text-success">
                                <p className="text-dark">Status</p>
                                <span
                                  style={{
                                    color:
                                      user.tr_status === "Success"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {user.tr_status}
                                </span>
                              </div>
                              </div>

                              <div className="d-flex justify-content-center mt-2">
                                <p className="text-dark pe-1">Transaction Time: </p>
                                {user.created_at}
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr className="mt-5">
                        <td colSpan="5">
                          No data available or something went wrong.
                        </td>
                      </tr>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      </Container>
    </>
  );
}
