import React, { useState, useEffect } from 'react'
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import '../../../src/Style.scss'

export default function Notification() {

  const [users, setUsersnote] = useState([]);
  useEffect(() => {
    loaduser();
  }, [])
// Function to remove HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};
  const loaduser = async () => {
     const user_id = window.sessionStorage.getItem("user_id");

    // alert(user_id);
    let response = await axios.post (`${process.env.REACT_APP_API_URL}/notice.php`); 
 var note = response.data;
 console.warn(note)
   setUsersnote(note);
  }



  return (
    <>

    <section id="notification" className='margin-bottom-88'>
        <div>
        {/* {users && users.map((user) => ( */}
          <>
           <div className="notficationbox">
             <div className="d-flex justify-content-between align-items-center">
             <h6 className="colortitle">{users.title}</h6>
               {/* <p>Jai Baba ji Ki</p> */}
             </div>
               <p className="notificationdes text-light ">{stripHtmlTags(users.description)}</p>
               <div className="d-flex justify-content-between">
               {/* <p>Date:{user.date}</p> */}
               {/* <p>Timer:09:15:21</p> */}
               </div>
           </div>
           
           </>
         
        </div>

    </section>
      
    </>
  )
}




