


import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'; 


function FAQ() {
  const [iframeContent, setIframeContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/faq.php`);
        const result = await response.text(); // Assuming the API returns HTML content  
        setIframeContent(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      // setIsLoading(false);
    };

    fetchData();
  }, []);

  return (

    <>   
    <p style={{paddingTop:"60px", margin:"10px"}}>
    <div className='margin-bottom-88 mb-0'>
    <iframe
                title="API Content"
                srcDoc={iframeContent}  // Set the HTML content here
                style={{ width: '100%', height: '100vh' }}
              />
        </div>

    </p>
    </>
  )
}

export default FAQ

// import React, { useState, useEffect } from 'react'
// import axios from "axios";

// export default function FAQ() {

//   const [users, setUsersnote] = useState([]);
//   useEffect(() => {
//     loaduser();
//   }, [])

//   const loaduser = async () => {
//      const user_id = window.sessionStorage.getItem("user_id");

//     // alert(user_id);
//     let response = await axios.post (`${process.env.REACT_APP_API_URL}/DL_faq.php`); 
//  var note = response.data;
//  console.warn(note)
//    setUsersnote(note);
//   }
//   return (
//     <>
//     <section id="notification" className='margin-bottom-88'>
//         <div>
//         {/* {users && users.map((user) => ( */}
//           <>
//            <div className="notficationbox">
//              <div className="d-flex justify-content-between align-items-center">
//              <h6 className="text-black mb-0">Title {users.short_description}</h6>
//                {/* <p>Jai Baba ji Ki</p> */}
//              </div>
//                <p className="notificationdes">{users.description}</p>
//                <div className="d-flex justify-content-between">
//                {/* <p>Date:{user.date}</p> */}
//                {/* <p>Timer:09:15:21</p> */}
//                </div>
//            </div>
           
//            </>
         
//         </div>

//     </section>
      
//     </>
//   )
// }




























