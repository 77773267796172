import React from 'react'
import { Link } from 'react-router-dom'



function Share() {
  return (
    <div>
     <Link to="https://www.facebook.com/groups/918829315482403/?ref=share_group_link">
                      <i class="bi bi-facebook"></i>
                    </Link>
    </div>
  )
}

export default Share