import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import single from "../../assets/img/single.png";
import jodi from "../../assets/img/jodi.png";
import card from "../../assets/img/card.png";
import card3 from "../../assets/img/card3.png";
import double from "../../assets/img/double.png";
import full_card from "../../assets/img/full_card.png";
import singlepanna from "../../assets/img/singlepanna.jpg";
import axios from "axios";


const Jodi_Pana = () => {
  const [users, setUsers] = useState("");
  const url = new URL(window.location.href);
  const gameid = url.searchParams.get('id');
  const url1 = new URL(window.location.href);
  const name = url1.searchParams.get('name');;
//   alert(gameid)
console.warn(gameid)   
  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    //  alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("market_id", gameid);
    formData.append("dev_id", dev_id);
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes);
      console.warn(objectRes);
    });
  };

  return (
    <>


  <section id="pana" className="margin-bottom-88 mt-5">
    <Container>
    <span className="ml-10px mt-10" style={{background:"red"}}> </span>

    <Row className="all_pana_box" style={{marginTop:'200px', marginLeft:'30px'}}>
        
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Jodi">
            <div className="panna_img">
                <img src={card} alt="pana" className="img-fluid"/>
                </div>
                <span>Jodi</span>
            </Link>
        </Col>
        <Col xs={6}>
            <Link className="pana_box" to="/Play-Harruf">
            <div className="panna_img">
                <img src={full_card} alt="pana" className="img-fluid"/>
                </div>
                <span>Harrf</span>
            </Link>
        </Col>
    </Row>
    </Container>
  </section>
  </>
  );
};

export default Jodi_Pana;
