import React, { useState, useEffect } from 'react';
import axios from 'axios';

import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

// Function to remove HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

export default function Instruction() {
  const [users, setUsersnote] = useState([]);

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = window.sessionStorage.getItem('user_id');

    // alert(user_id);
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/instruction.php`);
    var note = response.data;
    console.warn(note);
    setUsersnote(note);
  };

  return (
    <>
     <section id="notification" className='margin-bottom-88'>
        <div>
        {/* {users && users.map((user) => ( */}
          <>
           <div className="notficationbox">
            
               <p className="notificationdes text-light ">{stripHtmlTags(users.description)}</p>
               <div className="d-flex justify-content-between">
               {/* <p>Date:{user.date}</p> */}
               {/* <p>Timer:09:15:21</p> */}
               </div>
           </div>
           
           </>
         
        </div>

    </section>
   
    </>
  );
}
