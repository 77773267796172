import React, { useEffect, useState } from "react";
import {Container} from 'react-bootstrap'
import { IoCall } from "react-icons/io5";
import { SiGmail } from "react-icons/si";
import whatsappicon from '../../assets/img/whatsappicon.png'
import axios from "axios";

export default function Contactus() {


    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
      loaduser();
    }, []); // Empty dependency array for initial component mount
  
    const loaduser = async () => {
      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
  
      let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("dev_id", dev_id);
  
      try {
        const response = await axios.post(url, formData);
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          setProfiles(res);
          console.warn(res);
        }
      } catch (error) {
        console.error("Error fetching profile data: ", error);
      }
    };
  


  return (
    <section className="wininghistory" id="contact_us">
        <Container fluid>
            <div className='bg-white contactus_details'>
                <div className="d-flex align-items-center">
                    <div className='icon_color'><IoCall /></div>
                    <div className='details_contact'>
                        <h4 className="mb-0">
                         Call 1: <span>{profiles.mobile1}</span>
                        </h4>
                    </div>
                    
                </div>
            </div>
            <div className='bg-white contactus_details'>
                <div className="d-flex align-items-center">
                    <div className='icon_color'><IoCall /></div>
                    <div className='details_contact'>
                        <h4 className="mb-0">
                         Call 1: <span>{profiles.mobile2}</span>
                        </h4>
                    </div>
                    
                </div>
            </div>
            <div className='bg-white contactus_details'>
                <div className="d-flex align-items-center">
                    <div className='icon_color'><img src={whatsappicon} width={15} height={15}/></div>
                    <div className='details_contact'>
                        <h4 className="mb-0">
                        Whatsapp: <span>{profiles.genral_setting_whatsapp}</span>
                        </h4>
                    </div>
                    
                </div>
            </div>
            <div className='bg-white contactus_details'>
                <div className="d-flex align-items-center">
                    <div className='icon_color '><SiGmail className="text-dark"/></div>
                    <div className='details_contact'>
                        <h4 className="mb-0">
                         Gmail: <span>{profiles.contactEmail}</span>
                        </h4>
                    </div>
                    
                </div>
            </div>
        </Container>
    </section>
  )
}
