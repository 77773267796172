import React, { useState, useEffect } from 'react'
import logo from "../../assets/img/logo.png";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";

export default function Notice() {

    const [users, setUsersnote] = useState([]);
    useEffect(() => {
      loaduser();
    }, [])
  // Function to remove HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
    const loaduser = async () => {
       const user_id = window.sessionStorage.getItem("user_id");
  
      // alert(user_id);
      let response = await axios.post (`${process.env.REACT_APP_API_URL}/notice.php`); 
   var note = response.data;
   console.warn(note)
     setUsersnote(note);
    }


    return (
        <div className="wininghistory" id="notice">
            <div className="user_profile">
                <div className="logoImg d-flex justify-content-center">
                    <img src={logo} alt="Logo" className="img-fluid" />
                </div>
                <div className="pagenotice">
                    <h4 className="text-center fw-bold">Notice</h4>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex justify-content-around align-items-center">
                              <div className='notice_logo'>
                                    <img src={logo} alt="logo"/>
                                </div>
                                <h3 className="mb-0 mx-3 notice_heading">
                                    Notice  
                                </h3>
                              </div>
                            </Accordion.Header>
                            {/* <Accordion.Body> */}
                            <h4 className='text-red'>{stripHtmlTags(users.title)}</h4>
                            {stripHtmlTags(users.description)}
                            {/* </Accordion.Body> */}
                        </Accordion.Item>
                       
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
