import React, { useState, useEffect } from 'react';
import rup from "../../assets/img/rup.jpg";
import { Container } from 'react-bootstrap';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import singledigit from '../../assets/img/singledigit.png';
import jodidigit from '../../assets/img/jodidigit.png';
import fullsangam from '../../assets/img/fullsangam.png';
import doublepanna from '../../assets/img/doublepanna.png';
import halfsangam from '../../assets/img/halfsangam.png';
import triplepanna from '../../assets/img/triplepanna.png';
import singlepanna from '../../assets/img/singlepanna.png';


function Starlinegamerate() {

  const [users, setUserss] = useState("")


  useEffect(() => {
    kalyanGameRate();
  }, [])
  const kalyanGameRate = async () => {
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/starlineGameRate.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setUserss(objectRes[0]);
    });
  };


  return (
    <div className='market_ret'>
      <Container fluid>
        <>
          <div className="d-flex justify-content-between align-items-center marketrate_details">
            <div className="imagegame_market">
              <img src={singledigit} alt="single digit" />
            </div>
            <div className="contentrate">
              <h3>Single Digit</h3>
            </div>
            <div className="d-flex justify-content-around align-items-center rate_details">
              <div className='text-center text-white'>
                <h3>{users.single_digit_value1}</h3>
              </div>
              <span className='text-white'> &nbsp;-&nbsp;</span>
              <div className='text-center text-white'>
                <h3>{users.single_digit_value2}</h3>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center marketrate_details">
            <div className="imagegame_market">
              <img src={jodidigit} alt="single digit" />
            </div>
            <div className="contentrate">
              <h3>Single Panna</h3>
            </div>
            <div className="d-flex justify-content-around align-items-center rate_details">
              <div className='text-center text-white'>
                <h3>{users.single_pana_value1}</h3>
              </div>
              <span className='text-white'> &nbsp;-&nbsp;</span>
              <div className='text-center text-white'>
                <h3>{users.single_pana_value2}</h3>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center marketrate_details">
            <div className="imagegame_market">
              <img src={singlepanna} alt="single digit" />
            </div>
            <div className="contentrate">
              <h3>Double Panna</h3>
            </div>
            <div className="d-flex justify-content-around align-items-center rate_details">
              <div className='text-center text-white'>
                <h3>{users.double_pana_value1}</h3>
              </div>
              <span className='text-white'> &nbsp;-&nbsp;</span>
              <div className='text-center text-white'>
                <h3>{users.double_pana_value2}</h3>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center marketrate_details">
            <div className="imagegame_market">
              <img src={doublepanna} alt="single digit" />
            </div>
            <div className="contentrate">
              <h3>Triple Panna</h3>
            </div>
            <div className="d-flex justify-content-around align-items-center rate_details">
              <div className='text-center text-white'>
                <h3>{users.triple_pana_value1}</h3>
              </div>
              <span className='text-white'> &nbsp;-&nbsp;</span>
              <div className='text-center text-white'>
                <h3>{users.triple_pana_value2}</h3>
              </div>
            </div>
          </div>

          

          

        </>

      </Container>
    </div>
  )
}

export default Starlinegamerate