import React, { useState, useEffect } from 'react';
import { PoweroffOutlined } from '@ant-design/icons';
import { Button, DatePicker, Space } from 'antd';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';

export default function Resulthistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [iframeContent, setIframeContent] = useState(null);
  const [scrolltopdata, setscrolltopdata] = useState('');
  const marketchart_id = localStorage.getItem("marketchart_id");
  useEffect(() => {
    // Simulate loading for 2 seconds
    // const timer = setTimeout(() => {
    // setIsLoading(false);

  }, []);
  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY < 1) {
            setscrolltopdata('');
        } else {
            setscrolltopdata('scrolled');
        }
    });
}, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/webviewresultkalyanmarket.php?id=${marketchart_id}`);
        const result = await response.text(); // Assuming the API returns HTML content  
        setIframeContent(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // $('#submitfilter').on('click', function () {
  //   window.location.reload()
  // });

  // const Buttonrelod = () => {
  //   // Handle filter submit logic here
  //   // You can update state or perform any other necessary actions
  //   window.location.reload();
  // };
  return (
    <>
      {isLoading && (
        // <>
        // <div className="loader_roomcode">
        //   <img src={loader} alt="loader" />
        // </div>
        // </>
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      {!isLoading && (
        <section id="Help" className='margin-bottom-88 margin-top_44 mt-5'>
          <div className='margin-bottom-88 mb-0 margin-top_44'>
            {/* <button className={`btn-primary btn_getresult ${scrolltopdata}`} onClick={Buttonrelod}>Search</button> */}
            <iframe
              title="API Content"
              srcDoc={iframeContent}
              style={{ width: '100%', height: '100vh' }}
              // onClick={() => window.location.reload()}
            />
          </div>
        </section>
      )}
    </>

  )
}
