import React, { useState, useRef, useEffect } from "react";
// import { Button } from 'react-bootstrap';
import axios from "axios";
import { toast } from "react-toastify";
import loading from "../../assets/img/loading-gif.gif";
import filesearch from "../../assets/img/filesearch.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

export default function Play_Rashi_Jodi() {
  const [areCombinationsGenerated, setAreCombinationsGenerated] =
    useState(false);
  const [isListVisible, setIsListVisible] = useState(true);

  const [loadingbutton, setLoadingbutton] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [nums, setNums] = useState("");
  const [st, setSt] = useState([]);
  const [points, setPoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const [selectedResultType, setSelectedResultType] = useState("result");
  const [appmanagerdata, setAppmanager] = useState(null);
  const [loading2, setLoading2] = useState(false);
  const [currentDate, setCurrentDate] = useState(getFormattedDate());

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const [enteredNumber, setEnteredNumber] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");
  const [numberList, setNumberList] = useState([]);
  const [matchingList, setMatchingList] = useState([]);

  //   const generateNumberList = (input) => {
  //     if (input.length === 1) {
  //         setNumberList(
  //           ["12", "13", "14", "15", "23", "24", "25", "34", "35", "45"].filter(
  //             (item) => item.startsWith(input)
  //           )
  //         );
  //       } else if (input.length === 2) {
  //       const firstDigit = input.charAt(0);
  //       setNumberList(
  //         ["12", "13", "14", "15", "23", "24", "25", "34", "35", "45"].filter(
  //           (item) => item.startsWith(firstDigit)
  //         )
  //       );
  //     } else {
  //       setNumberList([]);
  //     }
  //   };
  
  const generateNumberList = (input) => {
    const allCombinations = [
      "12",
      "13",
      "14",
      "15",
      "23",
      "24",
      "25",
      "34",
      "35",
      "45",
    ];

    if (isListVisible) {
      if (input.length === 1) {
        setNumberList(allCombinations.filter((item) => item.includes(input)));
      } else if (input.length === 2) {
        const firstDigit = input.charAt(0);
        const secondDigit = input.charAt(1);

        setNumberList(
          allCombinations.filter(
            (item) => item.includes(firstDigit) && item.includes(secondDigit)
          )
        );
      } else {
        setNumberList([]);
      }
    }
  };

  const generateMatchingList = (selectedValue) => {
    const matching = [
      ["12", "17", "21", "26", "62", "67", "71", "76"],
      ["13", "18", "31", "36", "63", "68", "81", "86"],
      ["14", "19", "41", "46", "64", "69", "91", "96"],
      ["01", "06", "10", "15", "51", "56", "60", "65"],
      ["23", "28", "32", "37", "73", "78", "82", "87"],
      ["24", "29", "42", "47", "74", "79", "92", "97"],
      ["02", "07", "20", "25", "52", "57", "70", "75"],
      ["34", "39", "43", "48", "84", "89", "93", "98"],
      ["03", "08", "30", "35", "53", "59", "80", "85"],
      ["04", "09", "40", "45", "54", "59", "90", "95"],
    ];

    const matchingIndex = parseInt(selectedValue.charAt(0)) - 1;

    if (matchingIndex >= 0 && matchingIndex < matching.length) {
      setMatchingList(matching[matchingIndex]);
      const newTotalPoints = matching[matchingIndex].reduce(
        (acc, digit) => acc + parseInt(points),
        0
      );
      setTotalPoints(newTotalPoints);
    } else {
      setMatchingList([]);
      setTotalPoints(0);
    }
  };
  useEffect(() => {
    generateNumberList(enteredNumber);
  }, [enteredNumber]);

  useEffect(() => {
    generateMatchingList(selectedNumber);
  }, [selectedNumber]);

  const handleNumberClick = (clickedNumber) => {
    setEnteredNumber(clickedNumber);
    setIsListVisible(false); // Hide the list after selecting an item
  };
  const validateEnteredNumber = () => {
    const isValid = /^\d+$/.test(enteredNumber);
    return isValid;
  };
 
  const handleButtonClick = () => {
    if (!validateEnteredNumber()) {
      toast.error("Please enter a valid number");
      return;
    }
    const validatePoints = () => {
  
      const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
      return isValid;
    };
    
    if (!validatePoints()) {
      toast.error("Minimum bet ₹ 10");
      return;
    }
    // if (!validatePoints()) {
    //   toast.error("Please enter a valid points value");
    //   return;
    // }
  
    setSelectedNumber(enteredNumber);
  };

  const handleDeleteItem = (index) => {
    const newMatchingList = [...matchingList];
    newMatchingList.splice(index, 1);
    setMatchingList(newMatchingList);

    const newTotalPoints = newMatchingList.reduce(
      (acc, digit) => acc + parseInt(points),
      0
    );
    setTotalPoints(newTotalPoints);
  };

  useEffect(() => {
    loaduser();
    app_manager();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/app_setting.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      var setpoints = response.data.points;
      setMaxpoints(setpoints);

      // console.warn(min_redeem)
      // setDeposit(mindeposit);
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setAppmanager(objectRes);
      var setmininumbet = objectRes.jodi_min;
      console.warn(setmininumbet);
      setMinibet(setmininumbet);
      var setmax_betnumbet = objectRes.jodi_max;
      console.warn(setmax_betnumbet);
      setMaxbet(setmax_betnumbet);
      // console.warn(setmax_betnumbet);
    });
  };
  const loaduser = async () => {
    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const gameid = localStorage.getItem("market_id");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
      // console.warn(url);
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("market_id", gameid);
      formData.append("dev_id", dev_id);
      var config = {
        method: "POST",
        url: url,
        body: formData,
      };
      axios
        .post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          // var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          setMinibet(setmininumbet);
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints);
          console.warn(setpoints);
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes.points);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      //   setLoading(false);
    }
  };

  const playgamecrossing = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    if (!validateEnteredNumber()) {
      toast.error("Please enter a valid number");
      return;
    }
    const validatePoints = () => {
  
      const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
      return isValid;
    };
    if (!validatePoints()) {
      toast.error("Minimum bet ₹ 10");
      return;
    }
    if (parseInt(setminibet) > parseInt(points)) {
      toast.error(`Minimum Bet Placed ${appmanagerdata.jodi_min}`);
      return;
    }
    if (parseInt(MaxbetAmt) < parseInt(points)) {
      console.warn(MaxbetAmt);
      toast.error(`Maximum Bet Placed ${appmanagerdata.jodi_max}`);
      return;
    }
    if (users.points < points) {
      toast.error(`You Dont Have Sufficient Balance`);
      return;
    }

    try {
      setLoadingbutton(true);

      const betList = matchingList
        .map((item, index) => {
          return {
            betkey: item,
            betamount: points,
            session_name: "open",
            bettype: "8",
          };
        })
        .filter((item) => item.betamount > 0);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/play_game.php`,
        {
          user_id: user_id,
          dev_id: dev_id,
          market_id: gameid,
          app_id: process.env.REACT_APP_API_ID,
          BetList: betList,
          dev_model: "web",
          market_type: "mainMarket",
          devName: "sumsang",
        }
      );

      setIsButtonVisible(true);
      if (response.data.success == 1) {
        // setShowSubmitButton(false);

        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2500,
        }).then((result) => {
          navigate("/");
        });
      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: "error",
        });
        return;
      }
      // setCurrentDate(getFormattedDate());

      // return;
    } catch (error) {
      console.error("Game store:", error);
      toast.error(
        "An error occurred while Game store. Please try again later."
      );
    } finally {
      setLoadingbutton(false);
    }
  };



  return (
    <>
      <section id="crossing">
        <div className="d-flex justify-content-between px-3">
          <div className="points">
  
          </div>
        </div>
        <div className="">
          <div className="inputbox mt-5">
    
            <input
              type="date"
              className="form-control icondate"
              value={currentDate}
              placeholder="Withdraw"
            />
          </div>

          <div className="justify-conten-between formnumber">
            <div className="form-group d-flex align-items-center">
              <label className="text-white">First Jodi</label>

              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                value={enteredNumber}
                onChange={(e) => {
                  setEnteredNumber(e.target.value);
                  setIsListVisible(true);
                }}
                placeholder="Enter a number"
                required
              />
            </div>

            <div>
            {isListVisible && (
  <ul style={{
    backgroundColor: "black",
    marginLeft: "119px",
    listStyleType: "none",zIndex:'9999',
    border: numberList.length > 0 ? "2px white solid" : "none"
  }}>
    {numberList.map((item, index) => (
      <li key={index} onClick={() => handleNumberClick(item)}>
        {item}
      </li>
    ))}
  </ul>
)}

            </div>
          </div>
          <div className="formnumber">
            <div className="form-group d-flex align-items-center">
              <label className="text-white">Points</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                placeholder="Enter Point Here"
                value={points}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData
                    .getData("text/plain")
                    .replace(/[^0-9]/g, "")
                    .slice(0, 2);
                  document.execCommand("insertText", false, pastedText);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  setPoints(inputValue);
                }}
                required
              />
            </div>
          </div>

          <div className="form-btn">
            <button className="btn-add w-100" onClick={handleButtonClick}>
              Add
            </button>
          </div>
        </div>

        <div className="totalpoints d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-black"> ₹</p>
            <p className="ms-1 text-black">{totalPoints} /-</p>
          </div>

          {users.remaining_time_in_seconds > 0 ? (
            <>
              <button
                className="btn-add"
                onClick={playgamecrossing}
                disabled={loadingbutton}
              >
                <span>Submit</span>
                {loadingbutton && <Spinner animation="border" />}
              </button>
            </>
          ) : (
            <button className="btn-add">
              <span>Bat Has Bin Colsed</span>
            </button>
          )}
        </div>
        <ToastContainer />
        <table className="table tablecrossing mb-2 mt-2">
          {matchingList.length > 0 ? (
            <thead>
              <tr>
                <th>Session</th>
                <th>Digit</th>
                <th>Points</th>
                <th>Action</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {matchingList.map((item, index) => (
              <tr key={index}>
                <td>open</td>
                <td>{item}</td>
                <td>{points}</td>
                <td
                  className="text-danger"
                  onClick={() => handleDeleteItem(index)}
                >
                  <i className="bi bi-trash3"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  );
}
